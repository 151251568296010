.portfolio__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.section-title {
    margin-bottom: 2.5rem;
    text-align: center;
}

.other-projects-title {
    margin-top: 5rem;
}

/* Featured Projects Styles */
.featured__container {
    margin-bottom: 4rem;
}

.featured__item {
    background: var(--color-bg-variant);
    padding: 2rem;
    border-radius: 2rem;
    border: 1px solid var(--color-primary-variant);
    transition: var(--transition);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.featured__item:hover {
    border-color: var(--color-primary);
    background: transparent;
    transform: translateY(-5px);
}

.featured__item h3 {
    font-size: 1.4rem;
    margin: 1.5rem 0 2rem;
    color: var(--color-primary);
}

/* Other Projects Slider Styles */
.other-projects__container {
    width: 100%;
    padding: 2rem 0 4rem;
    position: relative;
    max-width: 900px;
    margin: 0 auto;
    padding-bottom: 4rem;
    margin-bottom: 2rem;
}

.other-project__slide {
    transition: all 0.3s ease;
    padding-bottom: 3rem;
}

.other-project__item {
    background: var(--color-bg-variant);
    padding: 0.8rem;
    border-radius: 1rem;
    border: 1px solid transparent;
    transition: all 0.3s ease;
    transform: scale(0.8);
}

/* Center slide styling */
.swiper-slide-active .other-project__item {
    transform: scale(0.95);
    border-color: var(--color-primary);
    background: var(--color-bg-variant);
}

.portfolio__item-image {
    border-radius: 0.8rem;
    overflow: hidden;
    margin-bottom: 0.6rem;
}

.portfolio__item-image img {
    width: 100%;
    height: auto;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.other-project__item h3 {
    margin: 0.6rem 0;
    font-size: 0.85rem;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.project-description {
    color: var(--color-light);
    font-size: 0.75rem;
    text-align: center;
    margin-bottom: 0.8rem;
    height: 2.4rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.other-project__item .portfolio__item-cta {
    display: flex;
    gap: 0.3rem;
    justify-content: center;
    margin-bottom: 0.2rem;
}

.other-project__item .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.65rem;
    min-width: 60px;
    text-align: center;
}

/* Ensure buttons remain clickable and visible */
.other-project__item .btn:hover {
    transform: scale(1.05);
}

/* Swiper Custom Styles */
.swiper-pagination {
    position: relative;
    margin-top: 2rem;
}

.swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    background: var(--color-primary);
    opacity: 0.5;
    margin: 0 5px !important;
}

.swiper-pagination-bullet-active {
    opacity: 1;
    background: var(--color-primary);
}

/* Add custom styles for Swiper navigation arrows */
.swiper-button-next,
.swiper-button-prev {
    color: var(--color-primary) !important;
    width: 40px !important;
    height: 40px !important;
    background: var(--color-bg);
    border-radius: 50%;
    border: 1px solid var(--color-primary);
    transform: scale(0.7);
    top: 45% !important;
}

.swiper-button-next:after,
.swiper-button-prev:after {
    font-size: 20px !important;
}

.swiper-button-next {
    right: 10px !important;
}

.swiper-button-prev {
    left: 10px !important;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
    background: var(--color-primary);
    color: var(--color-bg) !important;
}

/* Media Queries for Medium Devices (Tablet) */
@media screen and (max-width: 1024px) {
    .portfolio__container,
    .featured__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }

    .featured__item {
        padding: 1.5rem;
    }
}

/* Hide navigation on smaller screens */
@media screen and (max-width: 768px) {
    .swiper-button-next,
    .swiper-button-prev {
        display: none;
    }
    
    .other-project__item {
        transform: scale(0.95);
        margin: 0 auto;
        max-width: 280px;
    }

    .other-projects__container {
        padding-left: 0;
        padding-right: 0;
        max-width: 100%;
    }

    .swiper-slide-active .other-project__item {
        transform: scale(1);
    }

    .project-description {
        height: auto;
        max-height: 3.6rem;
        margin-bottom: 1rem;
    }
}

/* Media Queries for Small Devices (Mobile) */
@media screen and (max-width: 600px) {
    .portfolio__container,
    .featured__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .featured__item {
        padding: 1.2rem;
    }

    .other-projects-title {
        margin-top: 3rem;
    }

    .other-project__item {
        max-width: 260px;
        padding: 0.8rem;
    }

    .portfolio__item-image {
        margin-bottom: 0.8rem;
    }

    .other-project__item h3 {
        font-size: 0.9rem;
        margin: 0.5rem 0;
    }

    .project-description {
        font-size: 0.75rem;
        margin-bottom: 0.8rem;
    }

    .other-project__item .btn {
        padding: 0.4rem 0.8rem;
        font-size: 0.75rem;
    }

    .swiper-pagination {
        margin-top: 1rem;
    }
}