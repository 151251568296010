nav {
  background: rgba(0, 0, 0, 0.3);
  width: fit-content;
  height: fit-content;
  display: block;
  padding: 0.7rem 1.7rem;
  z-index: 2;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 2rem;
  display: flex;
  gap: 0.8rem;
  border-radius: 3rem;
  backdrop-filter: blur(15px);
}

nav a {
  width: 45px;
  height: 45px;
  user-select: none;
  background: transparent;
  padding: 0.9rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: var(--color-light);
  font-size: 1.1rem;
  transition: var(--transition);
  
}

nav a .iconname {
  font-size: 0;
  opacity: 0;
  position: relative;
  transform: translateX(-100%);
  transition: var(--transition);
}
nav a:hover {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 100px;
  width: 105px;
}
nav a:hover .iconname {
  margin-left: 0.5rem;
  opacity: 1;
  font-size: 0.6rem;
  transform: translateX(0%);
  color: var(--color-white);
}
nav a.active {
  background: var(--color-primary);
  border-radius: 100px;
  width: 105px;
  color: var(--color-white);
}
nav a.active .iconname {
  transform: translateX(0%);
  font-size: 0.6rem;
  margin-left: 0.5rem;
  opacity: 1;
  color: var(--color-white);
}
